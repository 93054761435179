<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">采购退货</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<el-table :data="info.inOrderItems">
					<el-table-column prop="barCode" label="条形码">
					</el-table-column>
					<el-table-column prop="goodsName" label="名称">
					</el-table-column>
					<el-table-column prop="model" label="规格">
					</el-table-column>
					<el-table-column prop="salePrice" label="零售价" width="100">
					</el-table-column>
					<el-table-column prop="count" label="入库数量" width="100">
					</el-table-column>
					<el-table-column label="退货数量">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.returnCount" :min="1" :max="scope.row.count" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="退货总价">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.totalAmount" :min="0" size="small">
							</el-input-number>
						</template>
					</el-table-column>
					<el-table-column label="备注">
						<template slot-scope="scope">
							<el-input v-model="scope.row.remark" placeholder="请输入备注" clearable size="small">
							</el-input>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button @click="handleDeleteClick(scope.$index)" type="text" size="small">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="viewB">
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交
				</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info:{},  //数据
				id:this.$route.query.id,  //入库id
				dialogRemark_state:false,  //备注
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//删除
			handleDeleteClick(index) {
				let arr = [...this.info.inOrderItems];
				arr.splice(index,1);
				this.info.inOrderItems = arr;
			},
			//获取详情
			getTableData(){
				let params = {
					inorderId:this.id
				}
				this.$http.get("/store/in/info",params).then(res => {
					if (res.code === 0) {
						this.info = res.data
					}
				})
			},
			//提交
			handleSubmitClick(){
				let params = {
					inOrderId:this.info.id,
				}
				let arr = [];
				this.info.inOrderItems.map((item)=>{
					arr.push({
						productId:item.goodsId,
						count:item.returnCount,
						remark:item.remark,
						totalAmount:item.totalAmount
					})
				})
				params.outOrderItems = arr;
				this.$http.post("/store/out/PurchaseBack",params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已退货'
						});
						this.$router.go(-1);
					}
				})
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			background: #fff;
			height: 100%;

			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}
			.viewC {
				flex: 1;
				overflow-y: scroll;
			}

			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}

			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
